import consoleLog from "./consoleLog";
import dialogObserver from "../observers/DialogObserver";
import {
  ACCOUNT_VERIFICATION_DIALOG_TYPE,
  ERROR_MESSAGE_DIALOG_TYPE,
  LOW_BALANCE_DIALOG_TYPE,
  MESSAGE_KIND_ERROR,
  MESSAGE_KIND_LOW_BALANCE,
  MESSAGE_KIND_NOTIFICATION,
  MESSAGE_KIND_RO_ID_MISSING,
  MESSAGE_KIND_WARNING,
  NOTIFICATION_MESSAGE_DIALOG_TYPE,
  WARNING_MESSAGE_DIALOG_TYPE,
} from "../constants";

const MAX_CONNECTION_ATTEMPTS = 10;
const RECONNECT_INTERVAL = 5_000;
const PING_INTERVAL = 10_000;

let socket = null;
let channelId = -1;
let attemptCounter = 1;
let pingTimeout = null;
let gameOptions = {};

const handleOpen = event => {
  const commands = [{
    command: "subscribe",
    value: "atlant.public",
  }];

  if (channelId) {
    commands.push({
      command: "identify",
      value: `${channelId}`,
    });
  }

  for (const command of commands) {
    socket.send(JSON.stringify(command));
  }

  sendPing();
};

const handleMessage = event => {
  if (!event) {
    return;
  }

  try {
    const data = JSON.parse(event.data);

    if (data === "PONG") {
      return;
    }

    const message = JSON.parse(data);

    switch (message.kind) {
      case MESSAGE_KIND_NOTIFICATION: {
        return dialogObserver.fire(NOTIFICATION_MESSAGE_DIALOG_TYPE, message);
      }
      case MESSAGE_KIND_ERROR: {
        return dialogObserver.fire(ERROR_MESSAGE_DIALOG_TYPE, message);
      }
      case MESSAGE_KIND_WARNING: {
        return dialogObserver.fire(WARNING_MESSAGE_DIALOG_TYPE, message);
      }
      case MESSAGE_KIND_LOW_BALANCE: {
        return dialogObserver.fire(LOW_BALANCE_DIALOG_TYPE, { ...message, gameOptions });
      }
      case MESSAGE_KIND_RO_ID_MISSING: {
        return dialogObserver.fire(ACCOUNT_VERIFICATION_DIALOG_TYPE);
      }
    }
  } catch (err) {
    consoleLog(err)
  }
};

const handleError = event => {
  setTimeout(() => {
    attemptCounter += 1;

    if (attemptCounter > MAX_CONNECTION_ATTEMPTS) {
      attemptCounter = 1;
    }

    establishWebSocketConnection();
  }, RECONNECT_INTERVAL * attemptCounter);
};

const clear = () => {
  if (!socket) {
    return;
  }

  if (pingTimeout) {
    clearTimeout(pingTimeout);
    pingTimeout = null;
  }

  socket.removeEventListener("open", handleOpen);
  socket.removeEventListener("message", handleMessage);
  socket.removeEventListener("error", handleError);
  socket.removeEventListener("close", handleError);
  socket = null;
};

const sendPing = () => {
  pingTimeout = setTimeout(() => {
    socket.send(JSON.stringify({
      command: "ping",
      value: "ping",
    }));

    sendPing();
  }, PING_INTERVAL);
};

const establishWebSocketConnection = () => {
  clear();

  socket = new WebSocket(`${window.api.websocketsHost}/messages`);

  socket.addEventListener("open", handleOpen);
  socket.addEventListener("message", handleMessage);
  socket.addEventListener("error", handleError);
  socket.addEventListener("close", handleError);
};

export default function initWebSockets (options) {
  const id = options.getChannelId()
  if (id === channelId) {
    return
  }

  gameOptions = options
  channelId = id;
  establishWebSocketConnection();
}
