import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Link, Icon } from "../../ui";
import { REAL_MODE } from "../../lib";
import "./LoginCashierButton.css";

function LoginCashierButton ({
  gameOptions,
  onRealGameClick,
  onCashierClick,
}) {
  const { t } = useTranslation();

  const label = () => {
    return gameOptions.getGameMode() === REAL_MODE
      ? t("cashier")
      : t("play_for_real");
  };

  const color = () => {
    return gameOptions.getGameMode() === REAL_MODE
      ? "green"
      : "red";
  };

  const handleClick = () => {
    if (gameOptions.getGameMode() === REAL_MODE) {
      onCashierClick();
    } else {
      onRealGameClick();
    }
  };

  const cls = cx("l6r__main-menu-button--primary", {
    "l6r__cashier-button": gameOptions.getGameMode() === REAL_MODE,
  });

  return (
    <Link
      className={cls}
      text={label()}
      color={color()}
      icon={Icon.Cashier}
      onClick={handleClick}
    />
  );
}

LoginCashierButton.propTypes = {
  gameOptions: PropTypes.object,
  onRealGameClick: PropTypes.func,
  onCashierClick: PropTypes.func,
};

export default LoginCashierButton;
